import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { GridService } from 'app/_services/grid.service';
import { debounceTime, Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { IAction } from '../_models/IAction';
import { IGridData } from '../_models/IGridData';
import { IFilter } from '../_models/IFilter';
import { IHeader } from '../_models/IHeader';
import { ILine } from '../_models/ILine';
import { SortFilterController } from 'app/_controllers/sortFilterController';
import { GridController } from 'app/_controllers/gridController';
import { SelectExpandController } from 'app/_controllers/selectExpandController';
import { IActiveFilter } from 'app/_models/IActiveFilter';
import { MenuComponent } from 'app/menu-component/menu.component';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { CommunicationService } from 'app/_services/communication.service';
import { DetailGridComponent } from 'app/detail-grid-component/detail-grid.component';
import { UtilService } from 'app/_services/util.service';
import {
  IconDefinition, faDownLeftAndUpRightToCenter, faFilter, faTableColumns, faAnglesLeft,
  faChevronLeft, faAnglesRight, faChevronRight, faArrowUpLong, faArrowDownLong,
  faCheck, faXmark, faArrowUpWideShort, faCircle, faChevronDown
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {
  //#region Global Fields  
  @Input() darkMode: boolean;
  @Input() globalFontSize: number;
  @Input() tablePadding: number;
  @Input() userId: string;
  @Input() username: string;
  @Input() mobileView: boolean;
  @Input() detailedErrorsEnabled: boolean;
  nodeId: string;
  dateFormat: string = '-';
  showCompactGrid: boolean = false;
  shiftSelect: boolean = false;
  controlSelect: boolean = false;
  actionToExecute: IAction;
  keyColumn: string;//default sort column when no sort is selected  
  currentViewName: string;//stores current active view used for rad filtering
  columnEditorHeaders: IHeader[] = [];
  headers: IHeader[] = [];
  lines: ILine[] = [];
  compiledLines: ICompiledLine[] = [];
  expandedRows: number[] = [];
  //#endregion

  //#region Pagination Fields  
  pageSize: number = 20;
  totalRecords: number = 0;
  pageCount: number = 1;
  offset: number = 0;
  currentpage: number = 1;
  rowCountFrom: number;
  rowCountTo: number;
  //#endregion

  //#region Grid Layout Fields
  hasLayoutSaved: boolean;
  colWidths: string[] = [];
  prevColWidths: string[] = [];
  @ViewChild('tablerow', { static: false }) rowHeader!: any;
  reorderedHeaders: IHeader[] = [];
  saveUpdatedLayout: boolean = false;
  //#endregion

  //#region Sort & Filter Fields
  sortOrder: boolean = true;
  colSortedBy: string;
  sortString: string;
  showFilters: boolean = false;
  filterString: string;
  filters: IFilter[] = [];
  activeFilterCount: number = 0;
  showMobileFilters: boolean = false;
  showMobileSort: boolean = false;
  //#endregion

  //#region Misc Fields 
  dialogOpen: boolean = false;
  showNoRecordsFoundLabel: boolean = false;
  hasChildren: boolean = false;
  loadedFromDirectLink: boolean = false;
  nodeIdFromQueryString: number = -1;
  showImageView: boolean = false;
  imageToExpand: any;
  @ViewChild(DetailGridComponent) detailGrid: DetailGridComponent;
  @ViewChild(MenuComponent) detailMenu: MenuComponent;
  sortChanged: Subject<string> = new Subject<string>();
  filterChanged: Subject<IActiveFilter> = new Subject<IActiveFilter>();
  filterGridWithDelay: boolean = true;
  updatedFilterForGrid: IActiveFilter;
  zoomedDetailView: boolean = false;
  //#endregion      

  //#region Icons
  faDownLeftAndUpRightToCenter: IconDefinition = faDownLeftAndUpRightToCenter;
  faFilter: IconDefinition = faFilter;
  faTableColumns: IconDefinition = faTableColumns;
  faAnglesLeft: IconDefinition = faAnglesLeft;
  faChevronLeft: IconDefinition = faChevronLeft;
  faChevronRight: IconDefinition = faChevronRight;
  faAnglesRight: IconDefinition = faAnglesRight;
  faArrowUpLong: IconDefinition = faArrowUpLong;
  faArrowDownLong: IconDefinition = faArrowDownLong;
  faCheck: IconDefinition = faCheck;
  faXmark: IconDefinition = faXmark;
  faArrowUpWideShort: IconDefinition = faArrowUpWideShort;
  faCircle: IconDefinition = faCircle;
  faChevronDown: IconDefinition = faChevronDown;
  //#endregion

  constructor(
    public app: AppComponent,
    public gridService: GridService,
    public utilService: UtilService,
    private communicationService: CommunicationService,
    public sortFilterController: SortFilterController,
    public gridController: GridController,
    public selectExpandController: SelectExpandController
  ) {
    this.communicationService.base_grid_loadGrid$.subscribe(() => {
      this.loadGrid();
    })

    this.communicationService.detailgrid_grid_deselectParentLines$.subscribe(() => {
      this.deselectAll();
    });

    this.communicationService.detailgrid_grid_updateChildPinState$.subscribe(tab => {
      this.lines.forEach(line => {
        line.children.forEach(child => {
          if (child.tableIndex == tab.tableIndex) {
            child.pinned = tab.pinned;
            child.showPin = tab.showPin;
          } else {
            child.pinned = false;
            child.showPin = false;
          }
        })
      })
    });

    this.communicationService.detail_grid_grid_zoomIntoDetailView$.subscribe(() => {
      this.zoomedDetailView = !this.zoomedDetailView;
    });

    this.communicationService.action_base_closeDialog$.subscribe(() => {
      //when action dialog closed, enable enter key from filtering grid
      this.dialogOpen = false;
    });

    this.communicationService.rad_base_showActionDialog$.subscribe(() => {
      //when action dialog open, disable enter key from filtering grid
      this.dialogOpen = true;
    })

    this.filterChanged.pipe(debounceTime(1250)).subscribe(newFilter => {
      if (this.filterGridWithDelay) {
        this.buildFilterString(newFilter.value, newFilter.field, newFilter.isNull);
      }
    });

    this.sortChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.getDetails('master', this.nodeId, this.sortString, this.filterString);
    });
  }

  ngOnInit(): void {
    //make sure filters aren't applied and populate compiledLines if needed       
    if (sessionStorage.getItem('directedNodeId') != undefined) {
      this.nodeId = sessionStorage.getItem('directedNodeId');
      this.filterString = '';
      this.loadedFromDirectLink = true;
      if (sessionStorage.getItem('directLineId') != undefined) {
        this.compiledLines = [];
        this.compiledLines.push({ id: sessionStorage.getItem('directLineId').toString(), lineData: null, lineNumber: null });
        this.getPageNumber();
      } else {
        this.getDetails('master', this.nodeId, this.sortString, this.filterString);
      }

      return;
    }

    this.loadGrid();
  }

  loadGrid(): void {
    this.nodeIdFromQueryString = -1;
    this.showFilters = false;
    this.sortString = '';
    this.offset = 0;
    this.currentpage = 1;
    this.loadedFromDirectLink = false;
    this.nodeId = sessionStorage.getItem('nodeId');
    sessionStorage.removeItem('selectedChildTabIndex');
    sessionStorage.removeItem('viewNameChild');
    sessionStorage.removeItem('directURL');
    sessionStorage.removeItem('directLineId');
    sessionStorage.removeItem('directedNodeId');
    this.headers = [];
    this.lines = [];
    this.filters = [];
    this.compiledLines = [];
    this.getDetails('master', this.nodeId);
  }

  getDetails(viewName: string = 'master', keyValue: string = '1', sortString: string = '', filterString: string = ''): void {
    //#region Setup   
    this.app.showLoader();
    if (this.app.cookieService.check('CompactGrid')) {
      this.showCompactGrid = this.app.tryParseBoolean(this.app.cookieService.get('CompactGrid'));
    }

    if (this.filters.length == 0 && !this.loadedFromDirectLink) {
      filterString = this.sortFilterController.buildFilterStringFromCookies(this.nodeId, false);
      this.filterString = filterString;
    }

    this.sortString = '';
    if (sortString == '' && !this.loadedFromDirectLink) {
      let cookies = this.app.cookieService.getAll();
      for (let cookie in cookies) {
        if (cookie.includes('sort')) {
          let cookieNodeId = cookie.split('|')[0].split('sort')[1] == undefined ? 0 : cookie.split('|')[0].split('sort')[1];
          if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
            let cookieName = cookie.split('|')[1];
            let cookieValue = cookies[cookie];
            this.colSortedBy = cookieName;
            this.sortString = cookieValue;
            this.sortOrder = this.sortString.includes('asc') ? true : false;
            break;
          }
        }
      }

      sortString = this.sortString;
    } else {
      this.sortString = sortString;
    }

    this.showNoRecordsFoundLabel = false;
    let keys = ['nodeId', 'viewName', 'sortString', 'filterString', 'pageSize', 'keyValue', 'offset', 'username', 'userId'];
    let values = [this.nodeId, viewName, sortString, filterString, this.pageSize.toString(), keyValue, this.offset.toString(), this.username, this.userId];
    let formData = this.app.buildForm(keys, values);
    //#endregion             
    this.gridService.getData(formData).subscribe({
      next: (data: IGridData) => {
        if (data) {
          this.headers = data.headers;

          if (this.filters.length > 0)
            this.headers = this.sortFilterController.reloadHeaderDisplayFormats(this.filters, this.headers);

          if (this.filters.length == 0) this.loadFilters();//load filters[] when view has no saved filters

          //if filterstring exists from cookies then open filters if closed
          //rebuild filter array based on cookie filters
          if ((filterString.trim() != '' || this.filterString.trim() != '') && !this.showFilters) this.toggleFilters();

          if (data.lines.length == 0) {
            this.lines = [];
            this.showNoRecordsFoundLabel = true;
            this.app.hideLoader();
            this.totalRecords = 0;

            //load rad here if they're not already loaded when grid returns no data
            if (!sessionStorage.getItem('RadLoaded')) {
              this.communicationService.loadRadButtons(this.headers, this.currentViewName);
            }

            if (this.filterString.length > 0)
              this.app.alertInfo(this.app.translations.ALERT_Info_NoRecordsFromFilters);

            this.rowCountFrom = 0;
            this.rowCountTo = 0;

            return;
          }

          let result = this.gridController.formatSanitise(data.lines, this.headers, this.filters);
          this.headers = result.headers;
          this.lines = result.lines;
          this.filters = result.filters;

          if (sessionStorage.getItem('directLineId') != undefined && this.compiledLines.length == 1) {
            let line = this.lines.find(line => line.rowId == this.compiledLines[0].id);
            if (line) {
              this.compiledLines[0].lineData = line.list;
              this.compiledLines[0].lineNumber = line.lineNumber;
            }
            sessionStorage.removeItem('directLineId');
          }

          this.keyColumn = data.keyColumn;
          this.hasLayoutSaved = data.hasLayoutSaved;
          this.hasChildren = data.hasChildren;
          this.dateFormat = data.dateFormat;
          this.totalRecords = data.totalRecords;
          this.currentViewName = data.masterViewName;
          this.calculatePageCount();
          this.reExpandRows();
          this.reSelectRows();
          this.colWidths = [];
          this.reorderedHeaders = [];
          if (data.newColDifference) this.saveLayout();
          this.app.hideLoader();
          sessionStorage.setItem('isMaster', 'true');
        } else {
          this.headers = [];
          this.lines = [];
          this.app.hideLoader();
        }

        if (!data) this.currentViewName = 'master';
        if (this.loadedFromDirectLink) {
          this.loadedFromDirectLink = false;
          sessionStorage.removeItem('directURL');
          sessionStorage.removeItem('directedNodeId');
          sessionStorage.setItem('filterDirectView', `${this.currentViewName}|${JSON.stringify(this.compiledLines)}`);
        }

        if (!sessionStorage.getItem('RadLoaded')) {
          this.communicationService.loadRadButtons(this.headers, this.currentViewName);
        }

        this.rowCountFrom = this.currentpage > 1 ? this.offset : 1;
        this.rowCountTo = this.currentpage > 1 ? this.offset + this.lines.length : this.lines.length;

        if (this.mobileView) {
          this.activeFilterCount = 0;
          this.filters.forEach(filter => {
            if (filter.isActive) this.activeFilterCount++;
          })
        }
      },
      error: (errorLog) => {
        this.app.hideLoader();
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.alertError(`An error occured Ref: ${errorLog.error.refNumber}`);
      }
    });
  }

  //#region |GridLayout
  toggleCompactGrid(): void {
    this.showCompactGrid = !this.showCompactGrid;
    if (this.detailGrid != undefined) this.detailGrid.showCompactGrid = this.showCompactGrid;
  }

  getColWidth(): void {
    let result = this.gridController.getColumnWidth(this.prevColWidths, this.colWidths, this.rowHeader, this.headers);
    this.prevColWidths = result.previousColumnWidths;
    this.colWidths = result.columnWidths;
    this.headers = result.headers;

    //flag change difference in current and previous widths or in order of headers    
    this.headers.forEach((header, i) => {
      if ((this.colWidths[i] != this.prevColWidths[i]) || (this.reorderedHeaders.length > 0 && header != this.reorderedHeaders[i]) || this.saveUpdatedLayout) {
        this.saveLayout();
        this.saveUpdatedLayout = false;
        return;
      }
    })
  }

  saveLayout(): void {
    if (sessionStorage.getItem('isMaster')! == 'false') return;
    this.gridController.saveLayout(this.nodeId, 'master', this.username, this.headers).then(() => { return; });
  }

  getColOrder(headers: IHeader[]): void {
    this.reorderedHeaders = headers;
    this.saveUpdatedLayout = true;
  }
  //#endregion

  //#region |Sort
  sortFromMobile(): void {
    this.showMobileSort = false;
    let header = this.headers.find(header => header.field == this.colSortedBy);
    if (header) {
      this.sortString = this.sortOrder ? `${header.field} asc` : `${header.field} desc`;
      this.app.cookieService.set(`sort${this.nodeId}|${header.field}`, `${this.sortString}`, 365, '/', '', false);
    }
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  updateSortByColumnMobile(header: IHeader): void {
    if (header.displayFormat == 'IMAGE' || header.dataType == 'Byte[]') return;
    if (this.colSortedBy != header.field) {
      this.sortOrder = true;
      this.app.cookieService.delete(`sort${this.nodeId}|${this.colSortedBy}`);
      this.colSortedBy = header.field;
    } else {
      this.app.cookieService.delete(`sort${this.nodeId}|${this.colSortedBy}`);
      this.colSortedBy = '';
      this.sortString = '';
    }
  }

  sort(header: IHeader): void {
    if (header.displayFormat == 'IMAGE' || header.dataType == 'Byte[]') return;
    if (this.colSortedBy != header.field) {
      this.sortOrder = true;
      this.app.cookieService.delete(`sort${this.nodeId}|${this.colSortedBy}`);
    } else {
      this.sortOrder = !this.sortOrder;
    }

    this.colSortedBy = header.field;
    this.sortString = this.sortOrder ? `${header.field} asc` : `${header.field} desc`;
    this.app.cookieService.set(`sort${this.nodeId}|${header.field}`, `${this.sortString}`, 365, '/', '', false);
    this.sortChanged.next(header.field);
  }

  removeSort(header: string): void {
    this.app.cookieService.delete(`sort${this.nodeId}|${header}`);
    this.colSortedBy = '';
    this.sortString = '';
    this.sortOrder = true;
    this.getDetails('master', this.nodeId, '', this.filterString);
  }
  //#endregion

  //#region |Filter      
  loadFilters(): void { this.filters = this.sortFilterController.loadFilters(this.headers); }

  nullifyFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();
    if (filter.dataType == 'Byte[]') return;
    filter = this.sortFilterController.nullifyFilter(filter, header);
    this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
  }

  nullifyDateFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();

    if (filter.isNull) {
      filter.isNull = false;
      filter.value = '';
      return;
    }

    filter = this.sortFilterController.nullifyFilter(filter, header);
  }

  updateValue(value: string, col: string, event: any, filter: IFilter): void {
    filter.isActive = true;
    let val = value == undefined ? event.checked : value;
    if (typeof val == 'string' && val.trim() == '') {
      filter.isNull = false;
      filter.isActive = false;
    }

    if (filter.displayFormat == 'CHECK') filter.isNull = false;

    if (!this.mobileView) {
      this.updatedFilterForGrid = { field: col, value: val, isNull: false };
      if (this.filterGridWithDelay) {
        this.filterChanged.next({ field: col, value: val, isNull: false });
      }
      this.filterGridWithDelay = true;
    } else {
      filter.value = val;
    }
  }

  createRangeFilter(filter: IFilter): void {
    if (filter.value.includes('null')) {
      this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
      return;
    }

    if (filter.range[0] == undefined || filter.range[1] == undefined) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidDateRange);
      return;
    }

    filter = this.sortFilterController.createRangeFilter(filter.range[0], filter.range[1], filter);
    if (!this.mobileView)
      this.buildFilterString(`${filter.displayFormat};${filter.range[0]}|RANGE|${filter.range[1]}`, filter.field, false);
  }

  buildFilterString(value: any, field: string, isNull: boolean): void {
    if (this.updatedFilterForGrid && !this.filterGridWithDelay) {
      this.filterString = this.sortFilterController.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.field, this.filters, Number(this.nodeId), this.updatedFilterForGrid.isNull, false, '', '');
    } else {
      this.filterString = this.sortFilterController.buildFilterString(value, field, this.filters, Number(this.nodeId), isNull, false, '', '');
    }

    this.offset = 0;
    this.currentpage = 1;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  buildFilterStringFromMobile(): void {
    this.showMobileFilters = false;
    this.filters.forEach(filter => {
      if (typeof filter.value == 'string' && filter.value.includes('|RANGE|')) {
        let type = filter.value.split(';')[0];
        let from = filter.value.split(';')[1].split('|RANGE|')[0];
        let to = filter.value.split(';')[1].split('|RANGE|')[1];
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, `type@${type}|RANGEFROM;${from}|RANGETO;${to}`, 365, '/', '', false);
      } else if (filter.value == 'null') {
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, `${filter.value}||string`, 365, '/', '', false);
      } else if (filter.value.includes('null')) {
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, filter.value, 365, '/', '', false);
      } else if (typeof filter.value == 'boolean' && filter.value) {
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, `1$bool`, 365, '/', '', false);
      } else if (typeof filter.value == 'boolean' && !filter.value) {
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, `0$bool`, 365, '/', '', false);
      } else {
        this.app.cookieService.set(`filter${this.nodeId}|${filter.field}`, `${filter.value}`, 365, '/', '', false);
      }

      if (typeof filter.value == 'string' && filter.value.trim() == '') {
        this.app.cookieService.delete(`filter${this.nodeId}|${filter.field}`);
      }
    })

    this.filterString = this.sortFilterController.buildFilterString('', '', this.filters, Number(this.nodeId), false, false, '', '');
    this.offset = 0;
    this.currentpage = 1;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  clearFilter(filterToClear: IFilter): void {
    if (filterToClear.dataType == 'Byte[]') return;
    this.app.cookieService.delete(`filter${this.nodeId}|${filterToClear.field}`);

    this.filterString = this.sortFilterController.clearFilter(filterToClear, this.filters);
    if (!this.mobileView) {
      this.offset = 0;
      this.currentpage = 1;
      this.getDetails('master', this.nodeId, this.sortString, this.filterString);
    }
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      this.filters = this.sortFilterController.clearAllFilters(this.filters, this.nodeId, 'master');
      this.filterString = '';
      this.getDetails('master', this.nodeId, this.sortString, this.filterString);
      return;
    }

    //populate filter values in filter[] from cookies using filterstring
    //at this point filterstring will already been populated with cookie values        
    let cookies = this.app.cookieService.getAll();
    for (const cookie in cookies) {
      let cookieNodeId = cookie.split('|')[0].split('filter')[1] == undefined ? 0 : cookie.split('|')[0].split('filter')[1];
      if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
        let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
        this.filters = result.filters;
        this.headers = result.headers;
      }
    }
  }

  preSelectCheckBoxFilter(header: IHeader, filterField: string, filterValue: string): boolean {
    return this.sortFilterController.preSelectCheckBoxFilter(header, filterField, filterValue);
  }

  getDateTimeFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateTimeFilterTooltip(filter, this.dateFormat);
  }

  getDateFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateFilterTooltip(filter, this.dateFormat);
  }
  //#endregion  

  //#region |Selection|Expansion
  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') {
      this.controlSelect = false;
      this.shiftSelect = true;
    }

    if (event.key == 'Control') {
      this.shiftSelect = false;
      this.controlSelect = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') this.shiftSelect = false;
    if (event.key == 'Control') this.controlSelect = false;

    if (event.key == 'Enter' && this.updatedFilterForGrid && !this.dialogOpen) {
      this.filterGridWithDelay = false;
      this.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.value.field, this.updatedFilterForGrid.value.isNull);
      this.updatedFilterForGrid = undefined;
    }
  }

  compileLines(selectedLine: ILine): void {
    selectedLine.selected = !selectedLine.selected;

    if (this.mobileView) {
      if (!selectedLine.selected) {
        let result = this.selectExpandController.unselectRow(this.compiledLines, selectedLine);
        this.compiledLines = result.compiledLines
      } else {
        this.compiledLines.push({ id: selectedLine.rowId, lineData: selectedLine.list, lineNumber: selectedLine.lineNumber });
      }

      this.communicationService.filterRadButtons(this.currentViewName, this.compiledLines);
      return;
    }

    let result = this.selectExpandController.compileLines(selectedLine, this.shiftSelect, this.controlSelect, this.compiledLines, this.lines, '', '', false);
    this.compiledLines = result.compiledLines;
    this.lines = result.lines;

    if (this.detailGrid != undefined) {
      this.detailGrid.parentGridCompiledLines = this.compiledLines;
      this.detailGrid.deselectAll();
    }
    this.communicationService.filterRadButtons(this.currentViewName, this.compiledLines);
  }

  reExpandRows(): void {
    if (this.expandedRows.length == 0) return;
    this.lines = this.selectExpandController.reExpandRows(this.expandedRows, this.lines);
  }

  reSelectRows(): void {
    if (this.compiledLines.length == 0) return;
    this.lines = this.selectExpandController.reSelectRows(this.compiledLines, this.lines);
  }

  deselectAll(): void {
    this.compiledLines = [];
    this.lines.forEach(line => { line.selected = false; })
  }

  expand(expandedLine: ILine): void {
    sessionStorage.removeItem('childSortString');
    sessionStorage.removeItem('selectedChildTabIndex');

    expandedLine.expanded = !expandedLine.expanded;
    if (expandedLine.expanded) {
      this.expandedRows.push(Number(expandedLine.rowId));
      return;
    }

    //if row is collapsed remove it from array and collapse all expanded child rows   
    let index = this.expandedRows.findIndex(val => val === Number(expandedLine.rowId));
    if (index !== -1) this.expandedRows.splice(index, 1);
    let keys = Object.keys(sessionStorage);
    let keysToRemove = keys.filter(key => key.includes(`${this.nodeId}|Expanded`));
    keysToRemove.forEach(key => sessionStorage.removeItem(key));
    this.communicationService.filterRadButtons(this.currentViewName, this.compiledLines);
  }
  //#endregion

  //#region |Pagination
  changePageSize(event: any): void {
    this.offset = 0;
    this.currentpage = 1;
    this.pageSize = event.target.value;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  getPageNumber(): void {
    let formData = this.app.buildForm(['nodeId', 'userId'], [sessionStorage.getItem('directedNodeId')!.toString(), this.userId]);
    this.gridService.getKeyField(formData).subscribe({
      next: (data: { result: string }) => {
        if (data) {
          let keys = ['nodeId', 'keyField', 'keyValue'];
          let values = [sessionStorage.getItem('directedNodeId')!.toString(), data.result, this.compiledLines ? this.compiledLines[0].id : ''];
          let formData = this.app.buildForm(keys, values);
          this.gridService.getPageNumber(formData).subscribe({
            next: (data: { result: number }) => {
              if (data) {
                this.offset = (data.result * this.pageSize) - this.pageSize;
                this.currentpage = data.result;
                this.nodeId = sessionStorage.getItem('directedNodeId')!.toString();
                sessionStorage.removeItem('directedNodeId');
                this.getDetails('master', this.nodeId, this.sortString, this.filterString);
              }
            }, error: (errorLog) => {
              this.app.hideLoader();
              if (this.detailedErrorsEnabled) {
                sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
                this.app.router.navigate(["error"]);
                return;
              }

              console.log(errorLog);
              this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`);
            }
          });
        }
      }, error: (errorLog) => {
        this.app.hideLoader();
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`);
      }
    });
  }

  calculatePageCount(): void {
    this.pageCount = this.totalRecords / this.pageSize;
    if (this.totalRecords % this.pageSize > 0) this.pageCount = this.pageCount + 1;
    this.pageCount = Math.floor(this.pageCount);
  }

  next(): void {
    this.offset = this.offset + Number(this.pageSize);
    this.currentpage++;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  prev(): void {
    this.offset = this.offset - Number(this.pageSize);
    if (this.offset < 0) this.offset = 0
    this.currentpage--;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  first(): void {
    this.offset = 0;
    this.currentpage = 1;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  last(): void {
    this.offset = this.totalRecords - this.pageSize;
    if (this.offset < 0) this.offset = 0
    this.currentpage = this.pageCount;
    this.getDetails('master', this.nodeId, this.sortString, this.filterString);
  }

  isFirstPage(): boolean { return this.currentpage == 1; }
  isLastPage(): boolean { return this.currentpage == this.pageCount; }
  //#endregion 

  //#region |Misc         
  expandImage(image: any): void {
    this.communicationService.showImageDialog(image);
  }

  checkIndeterminate(value: any): boolean {
    return this.sortFilterController.checkIndeterminate(value);
  }

  openColumnDialog(): void {
    this.communicationService.showColumnDialog(this.headers, "master");
  }

  checkDateFormat(): string {
    return this.dateFormat.includes('-') ? 'dd-mm-yy' : 'dd/mm/yy';
  }

  getViewName(): void {
    sessionStorage.setItem('isMaster', 'true');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mobileView = window.innerWidth > window.innerHeight ? false : true;
  }
  //#endregion
}
