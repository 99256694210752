import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { IFilter } from '../_models/IFilter';
import { IHeader } from '../_models/IHeader';
import { IActiveFilter } from 'app/_models/IActiveFilter';
import { GridService } from 'app/_services/grid.service';
import { SortFilterController } from 'app/_controllers/sortFilterController';
import { AppComponent } from 'app/app.component';
import { GridController } from 'app/_controllers/gridController';
import { SelectExpandController } from 'app/_controllers/selectExpandController';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { ILine } from 'app/_models/ILine';
import { IGridData } from 'app/_models/IGridData';
import { IGridConfig } from 'app/_models/IGridConfig';
import { CommunicationService } from 'app/_services/communication.service';
import { 
  IconDefinition, faThumbTack, faFilter, faTableColumns, faMaximize, faArrowUpLong, 
  faArrowDownLong, faDownLeftAndUpRightToCenter, faCheck, faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-detail-grid',
  templateUrl: './detail-grid.component.html',
  styleUrls: ['./detail-grid.component.css']
})
export class DetailGridComponent implements OnInit {  
  //#region Input Fields
  @Input() children: IGridConfig[] = [];  
  @Input() currentCompiledId: string;
  @Input() rowId: number;
  @Input() globalFontSize: number;
  @Input() tablePadding: number;
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() showCompactGrid: boolean;
  @Input() darkMode: boolean;
  @Input() dateFormat: string;
  @Input() parentGridCompiledLines: any;
  @Input() parentGridExpandedLine: any;
  @Input() detailedErrorsEnabled: boolean;
  //#endregion

  //#region Global Collections  
  headers: IHeader[] = [];
  lines: ILine[] = [];
  compiledLines: ICompiledLine[] = [];
  columnEditorHeaders: IHeader[] = [];
  expandedRows: number[] = [];
  //#endregion

  //#region Grid Layout Fields
  hasLayoutSaved: boolean = true;
  colWidths: string[] = [];
  prevColWidths: string[] = [];
  @ViewChild('tablerowInner') rowHeader!: any;
  reorderedHeaders: IHeader[] = [];
  saveUpdatedLayout: boolean = false;
  //#endregion

  //#region Sort & Filter Fields
  sortOrder: boolean = true;
  colSortedBy: string;
  sortString: string;
  showFilters: boolean = false;
  filterString: string;
  filters: IFilter[] = [];
  showNoRecordsFoundLabel: boolean = false;
  //#endregion

  //#region Misc Fields 
  dialogOpen: boolean = false;
  keyColumn: string;
  currentViewName: string;
  previousViewName: string = '';
  hasChildren: boolean = false;
  selectedTabIndex: number = 0;
  shiftSelect: boolean = false;
  controlSelect: boolean = false;
  showImageView: boolean = false;
  imageToExpand: any;  
  filterChanged: Subject<IActiveFilter> = new Subject<IActiveFilter>();  
  @ViewChild(DetailGridComponent) detailGrid: DetailGridComponent;
  sortChanged: Subject<string> = new Subject<string>();
  showDetailLoader: boolean = false;
  filterGridWithDelay: boolean = true;
  updatedFilterForGrid: IActiveFilter;
  zoomedDetailView: boolean = false;  
  //#endregion   

  //#region Icons
  faThumbTack: IconDefinition = faThumbTack;
  faFilter: IconDefinition = faFilter;
  faTableColumns: IconDefinition =faTableColumns;
  faMaximize: IconDefinition = faMaximize;
  faArrowUpLong: IconDefinition = faArrowUpLong;
  faArrowDownLong: IconDefinition = faArrowDownLong;
  faDownLeftAndUpRightToCenter: IconDefinition = faDownLeftAndUpRightToCenter;
  faCheck: IconDefinition = faCheck;
  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  //#endregion
  
  constructor(
    public app: AppComponent,
    private sortFilterController: SortFilterController,
    private gridController: GridController,
    private selectExpandController: SelectExpandController,
    private gridService: GridService,
    private communicationService: CommunicationService
  ) {    
    this.communicationService.action_base_closeDialog$.subscribe(() => {
      //when action dialog closed, enable enter key from filtering grid
      this.dialogOpen = false;      
    });

    this.communicationService.rad_base_showActionDialog$.subscribe(() => {
      //when action dialog open, disable enter key from filtering grid
      this.dialogOpen = true;      
    })

    this.filterChanged.pipe(debounceTime(1250)).subscribe(newFilter => {
      if (this.filterGridWithDelay) {       
        this.buildFilterString(newFilter.value, newFilter.field, newFilter.isNull);
      }      
    });

    this.sortChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.getDetails(sessionStorage.getItem('viewNameChild')!, this.sortString, this.filterString);
    });
  }

  ngOnInit() {
    let tabIndex = 0;
    let caption = '';

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].tableIndex = i;
    }

    let cookies = Object.keys(this.app.cookieService.getAll()).filter(cookieName =>
      cookieName.includes(`pinned;${this.nodeId}`)
    );

    if (sessionStorage.getItem('selectedChildTabIndex') != undefined) {
      tabIndex = Number(sessionStorage.getItem('selectedChildTabIndex').toString().split('|')[1]);
      let tabName = sessionStorage.getItem('selectedChildTabIndex').toString().split('|')[0];
      caption = this.children[tabIndex] == undefined ? this.children[0].name : this.children[tabIndex].name;
      if (tabName == caption) {
        this.selectedTabIndex = tabIndex;
        if (cookies.length > 0) {
          let name = cookies[0].split(';')[2];
          let child = this.children.filter(child => child.name == name);
          if (child != undefined && child.length > 0) {
            child[0].pinned = true;
            child[0].showPin = true;
          } else {
            caption = this.children[0].name;
            this.selectedTabIndex = this.children[0].tableIndex;
          }
        }
      }
    } else if (cookies.length > 0) {
      let name = cookies[0].split(';')[2];
      let child = this.children.filter(child => child.name == name);
      if (child != undefined && child.length > 0) {
        child[0].pinned = true;
        child[0].showPin = true;
        caption = child[0].name;
        this.selectedTabIndex = child[0].tableIndex;
      } else {
        caption = this.children[0].name;
        this.selectedTabIndex = this.children[0].tableIndex;
      }
    } else {
      caption = this.children[tabIndex] == undefined ? this.children[0].name : this.children[tabIndex].name;
      let savedTabIndex = this.children[tabIndex] == undefined ? 0 : tabIndex;
      sessionStorage.setItem('selectedChildTabIndex', `${caption}|${savedTabIndex}`);
    }

    this.getDetails(caption);    
  }  

  getDetails(viewName: string = '', sortString: string = '', filterString: string = ''): void {
    //#region Setup      
    this.showDetailLoader = true;
    if (this.filterString == '' || this.filterString == undefined) {
      filterString = this.sortFilterController.buildFilterStringFromCookies(this.nodeId, true, this.rowId.toString(), this.children[this.selectedTabIndex].name);
      this.filterString = filterString;
    }

    this.sortString = '';
    if (sortString == '') {
      let cookies = this.app.cookieService.getAll();
      for (const cookie in cookies) {
        let child = this.children[this.selectedTabIndex];
        if (cookie.includes('sortdetail') && cookie.split('|')[2] == child.name) {
          let cookieNodeId = cookie.split('|')[0].split('sortdetail')[1] == undefined ? 0 : cookie.split('|')[0].split('sortdetail')[1];
          if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
            let cookieName = cookie.split('|')[1];
            let cookieValue = cookies[cookie];
            this.colSortedBy = cookieName;
            this.sortString = cookieValue;
            this.sortOrder = this.sortString.includes('asc') ? true : false;
            break;
          }
        }
      }

      sortString = this.sortString;
    } else {
      this.sortString = sortString;
    }
    
    this.showNoRecordsFoundLabel = false;
    let keys = ['userId', 'nodeId', 'viewName', 'sortString', 'filterString', 'keyValue', 'startRow', 'username'];
    let values = [this.userId, this.nodeId, viewName, sortString, this.filterString, this.rowId.toString(), '0', this.username];
    let formData = this.app.buildForm(keys, values);
    //#endregion        
    this.gridService.getData(formData).subscribe({
      next: (data: IGridData) => {
        if (data) {               
          this.headers = data.headers;

          if (this.filters.length > 0) 
            this.headers = this.sortFilterController.reloadHeaderDisplayFormats(this.filters, this.headers);          
          if (this.filters.length == 0) this.loadFilters();//reload filters[] when changing tabs
          if ((filterString.trim() != '' || this.filterString.trim() != '') && !this.showFilters) this.toggleFilters();

          if (data.lines.length == 0) {
            this.lines = [];          
            this.showNoRecordsFoundLabel = true;
            this.showDetailLoader = false;
            return;
          }
          
          let result = this.gridController.formatSanitise(data.lines, this.headers, this.filters);
          this.headers = result.headers;
          this.lines = result.lines;
          this.filters = result.filters;

          this.dateFormat = data.dateFormat;
          this.keyColumn = data.keyColumn;
          this.hasLayoutSaved = data.hasLayoutSaved;
          this.hasChildren = data.hasChildren;
          this.reExpandRows();
          this.reSelectRows();
          this.colWidths = [];
          this.reorderedHeaders = [];
          if (data.newColDifference) this.saveLayout();
          if (this.currentViewName == 'master' || this.currentViewName == '' || this.currentViewName == undefined) this.currentViewName = data.masterViewName;
          if (this.previousViewName.length == 0) this.previousViewName = viewName;
          this.showDetailLoader = false;
          sessionStorage.setItem('isMaster', 'false');
          this.communicationService.updateRadHeaders(this.headers);
        }
      }, error: (errorLog) => {
        this.app.hideLoader();
        if(this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }
        
        console.log(errorLog);        
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`); 
      }
    });
  }
  
  //#region |GridLayout  
  getColWidth(): void {
    let result = this.gridController.getColumnWidth(this.prevColWidths, this.colWidths, this.rowHeader, this.headers);
    this.prevColWidths = result.previousColumnWidths;
    this.colWidths = result.columnWidths;
    this.headers = result.headers;

    this.headers.forEach((header, i) => {
      if ((this.colWidths[i] != this.prevColWidths[i]) || (this.reorderedHeaders.length > 0 && header != this.reorderedHeaders[i]) || this.saveUpdatedLayout) {
        this.saveLayout();
        this.saveUpdatedLayout = false;        
        return;
      }
    })
  }

  saveLayout(): void {
    if (sessionStorage.getItem('isMaster')! == 'true') return;
    this.gridController.saveLayout(this.nodeId, sessionStorage.getItem('viewNameChild')!.toString(), this.username, this.headers).then(() => { return; });
  }

  getColOrder(cols: IHeader[]): void { 
    this.reorderedHeaders = cols; 
    this.saveUpdatedLayout = true;    
  }
  //#endregion

  //#region |Sort
  sort(header: IHeader): void {
    if (header.displayFormat == 'IMAGE' || header.dataType == 'Byte[]') return;
    let child = this.children[this.selectedTabIndex];
    if (this.colSortedBy != header.field) {
      this.sortOrder = true;
      this.app.cookieService.delete(`sortdetail${this.nodeId}|${this.colSortedBy}|${child.name}`);
    } else {
      this.sortOrder = !this.sortOrder;
    }

    this.colSortedBy = header.field;
    this.sortString = this.sortOrder ? `${header.field} asc` : `${header.field} desc`;
    this.app.cookieService.set(`sortdetail${this.nodeId}|${header.field}|${child.name}`, `${this.sortString}`, 365, '/', '', false);
    this.sortChanged.next(header.field);
  }

  removeSort(header: string): void {
    let child = this.children[this.selectedTabIndex];
    this.app.cookieService.delete(`sortdetail${this.nodeId}|${header}|${child.name}`);
    this.colSortedBy = '';
    this.sortString = '';
    this.sortOrder = true;    
    this.getDetails(sessionStorage.getItem('viewNameChild')!.toString(), `${this.keyColumn} asc`, this.filterString);
  }
  //#endregion

  //#region |Filter
  loadFilters(): void { this.filters = this.sortFilterController.loadFilters(this.headers); }

  nullifyFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();
    if (filter.dataType == 'Byte[]') return;
    filter = this.sortFilterController.nullifyFilter(filter, header);
    this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
  }

  nullifyDateFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();

    if (filter.isNull) {
      filter.isNull = false;
      filter.value = '';
      return;
    }

    filter = this.sortFilterController.nullifyFilter(filter, header);
  }

  updateValue(value: string, col: string, event: any, filter: IFilter): void {
    filter.isActive = true;
    let val = value == undefined ? event.checked : value;
    if (typeof val == 'string' && val.trim() == '') {
      filter.isNull = false;
      filter.isActive = false;
    }

    if (filter.displayFormat == 'CHECK') filter.isNull = false;
    this.filterGridWithDelay = true;    
    this.updatedFilterForGrid = { field: col, value: val, isNull: false };
    this.filterChanged.next({ field: col, value: val, isNull: false });
  }

  createRangeFilter(filter: IFilter): void {
    if (filter.value.includes('null')) {
      this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
      return;
    }

    if (filter.range[0] == undefined || filter.range[1] == undefined) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidDateRange);
      return;
    }

    filter = this.sortFilterController.createRangeFilter(filter.range[0], filter.range[1], filter);
    this.buildFilterString(`${filter.displayFormat};${filter.range[0]}|RANGE|${filter.range[1]}`, filter.field, false);
  }

  buildFilterString(val: string, col: string, isNull: boolean): void {
    if (this.updatedFilterForGrid && !this.filterGridWithDelay) {      
      this.filterString = this.sortFilterController.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.field, this.filters, Number(this.nodeId), this.updatedFilterForGrid.isNull, true, this.rowId.toString(), this.children[this.selectedTabIndex].name);
    } else {      
      this.filterString = this.sortFilterController.buildFilterString(val, col, this.filters, Number(this.nodeId), isNull, true, this.rowId.toString(), this.children[this.selectedTabIndex].name);
    }
        
    this.getDetails(this.currentViewName, this.sortString, this.filterString);
  }

  clearFilter(filterToRemove: IFilter): void {
    if (filterToRemove.dataType == 'Byte[]') return;
    if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false') {
      this.app.cookieService.delete(`detailfilter${this.nodeId}|${filterToRemove.field};${this.children[this.selectedTabIndex].name}`);
    } else {
      let cookies = this.app.cookieService.getAll();
      for (const cookie in cookies) {
        if (cookie.includes(`detailfilter${this.nodeId}|${filterToRemove.field};${this.children[this.selectedTabIndex].name}|${this.rowId}`)) {
          this.app.cookieService.delete(cookie);
          break;
        }
      }
    }

    this.filterString = this.sortFilterController.clearFilter(filterToRemove, this.filters);
    this.getDetails(sessionStorage.getItem('viewNameChild')!.toString(), this.sortString, this.filterString);
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      this.filters = this.sortFilterController.clearAllFilters(this.filters, this.nodeId, this.children[this.selectedTabIndex].name, this.rowId.toString());//need to check this still for switching between modes
      this.filterString = '';
      this.getDetails(sessionStorage.getItem('viewNameChild')!, this.sortString, this.filterString);
      return;
    }

    let cookies = this.app.cookieService.getAll();
    for (let cookie in cookies) {
      let cookieNodeId = cookie.split('|')[0].split('detailfilter')[1] == undefined ? 0 : cookie.split('|')[0].split('detailfilter')[1];
      if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
        if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false' && cookie.split('|').length == 2) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        } else if (this.app.cookieService.get('RowFilters') == 'true' && cookie.split('|').length == 4 && this.rowId.toString() == cookie.split('|')[2]) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        }
      }
    }
  }

  preSelectCheckBoxFilter(header: IHeader, filterField: string, filterValue: string): boolean {
    return this.sortFilterController.preSelectCheckBoxFilter(header, filterField, filterValue);
  }

  getDateTimeFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateTimeFilterTooltip(filter, this.dateFormat);
  }

  getDateFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateFilterTooltip(filter, this.dateFormat);
  }
  //#endregion

  //#region |Selection|Expansion
  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') {
      this.controlSelect = false;
      this.shiftSelect = true;
    }

    if (event.key == 'Control') {
      this.shiftSelect = false;
      this.controlSelect = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') this.shiftSelect = false;
    if (event.key == 'Control') this.controlSelect = false;

    if (event.key == 'Enter' && this.updatedFilterForGrid && !this.dialogOpen) {     
      this.filterGridWithDelay = false;
      this.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.value.field, this.updatedFilterForGrid.value.isNull);
      this.updatedFilterForGrid = undefined;
    }
  }

  compileLines(selectedLine: ILine): void {
    selectedLine.selected = !selectedLine.selected;
    if (this.detailGrid != undefined) this.detailGrid.deselectAll();

    //deselects parent row when selecting a child row
    if (this.parentGridCompiledLines != undefined && this.parentGridExpandedLine != undefined) {
      this.parentGridCompiledLines = [];
      this.parentGridExpandedLine.selected = false;
    }

    let result = this.selectExpandController.compileLines(selectedLine, this.shiftSelect, this.controlSelect, this.compiledLines, this.lines, this.previousViewName, this.currentViewName, true);    
    this.compiledLines = result.compiledLines;
    this.lines = result.lines;
    this.currentViewName = result.currentViewName;
    sessionStorage.setItem('viewNameChild', this.currentViewName);
    this.communicationService.filterRadButtons(this.currentViewName, this.compiledLines);
  }

  reExpandRows(): void {
    if (sessionStorage.getItem(`${this.nodeId}|Expanded|${this.children[this.selectedTabIndex].name}`)) {
      this.expandedRows = [];
      let expandedIds = sessionStorage.getItem(`${this.nodeId}|Expanded|${this.children[this.selectedTabIndex].name}`).split(';');
      expandedIds.forEach((id) => { this.expandedRows.push(Number(id)); })
    }

    if (this.expandedRows.length == 0) return;
    this.lines = this.selectExpandController.reExpandRows(this.expandedRows, this.lines);
  }

  reSelectRows(): void {
    if (this.compiledLines.length == 0) return;
    this.lines = this.selectExpandController.reSelectRows(this.compiledLines, this.lines);
  }

  deselectAll(): void {
    this.compiledLines = [];
    if(this.parentGridCompiledLines == undefined || this.parentGridCompiledLines.length == 0) {      
      this.communicationService.filterRadButtons(this.currentViewName, this.compiledLines);
    }
    
    this.lines.forEach(line => { line.selected = false; })
  }

  expand(expandedLine: ILine): void {
    expandedLine.expanded = !expandedLine.expanded;
    if (expandedLine.expanded) {
      this.expandedRows.push(Number(expandedLine.rowId));
      let expandedIds = this.expandedRows.join(';');
      sessionStorage.setItem(`${this.nodeId}|Expanded|${this.children[this.selectedTabIndex].name}`, expandedIds);
      return;
    }

    let index = this.expandedRows.findIndex(val => val === Number(expandedLine.rowId));
    if (index !== -1) this.expandedRows.splice(index, 1);
    if (this.expandedRows.length > 0) {
      let expandedIds = this.expandedRows.join(';');
      sessionStorage.setItem(`${this.nodeId}|Expanded|${this.children[this.selectedTabIndex].name}`, expandedIds);
      return;
    }

    sessionStorage.removeItem(`${this.nodeId}|Expanded|${this.children[this.selectedTabIndex].name}`);
  }
  //#endregion

  //#region |Misc  
  toggleZoomDetailView(): void {
    this.zoomedDetailView = !this.zoomedDetailView; 
    this.communicationService.zoomIntoDetailView();
  }

  toggleTabs(tab: IGridConfig): void {
    this.children.forEach(child => {
      if (child.name != tab.name) {
        child.pinned = false;
        child.showPin = false;
        this.app.cookieService.delete(`pinned;${this.nodeId};${child.name}`);
      }
    });

    tab.pinned = !tab.pinned;
    tab.showPin = tab.pinned ? true : false;

    if (tab.pinned) {
      this.app.cookieService.set(`pinned;${this.nodeId};${tab.name}`, tab.name, 365, '/', '', false);
    } else {
      this.app.cookieService.delete(`pinned;${this.nodeId};${tab.name}`);
    }

    this.communicationService.updateChildPinState(tab);
  }

  showPin(tab: IGridConfig): void {
    tab.showPin = true;
  }

  hidePin(tab: IGridConfig): void {
    if (!tab.pinned) tab.showPin = false;
  }

  changeTab(event: any): void {
    sessionStorage.setItem('selectedChildTabIndex', `${this.children[event.index].name}|${event.index}`);
    this.headers = [];
    this.lines = [];
    this.filters = [];
    this.compiledLines = [];
    this.showFilters = false;
    this.filterString = '';
    this.sortString = '';
    this.currentViewName = this.children[event.index].name;
    sessionStorage.setItem('viewNameChild', this.currentViewName);
    this.selectedTabIndex = event.index;
    this.deselectAll();
    this.getDetails(this.currentViewName);
  }

  getViewName(): void {
    sessionStorage.setItem('isMaster', 'false');
    sessionStorage.setItem('viewNameChild', this.currentViewName);
  }

  expandImage(image: any): void {      
    this.communicationService.showImageDialog(image);    
  }

  openColumnDialog(): void { 
    this.communicationService.showColumnDialog(this.headers, this.currentViewName); 
  }

  checkDateFormat(): string { 
    return this.dateFormat.includes('-') ? 'dd-mm-yy' : 'dd/mm/yy'; 
  }

  checkIndeterminate(value: any): boolean { 
    return this.sortFilterController.checkIndeterminate(value); 
  }
  //#endregion
}
