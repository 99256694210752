import { Injectable, NgZone } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { LicensingService } from './licensing.service';
import { Subscription, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InactivityService {
  private activityEvents = ['mousemove', 'click'];
  private timeoutId: any;
  sessionValidationSubject: Subscription;

  constructor(
    private ngZone: NgZone,
    public app: AppComponent,
    private licensingService: LicensingService    
  ) {
    localStorage.removeItem('logoutReason');
    localStorage.removeItem('sessionResult');
    this.startMonitoring();
    this.resetTimeout();

    this.sessionValidationSubject = interval(600000).subscribe(() => {
      this.validateSession();
    });
  }

  startMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.addEventListener(event, this.resetTimeout.bind(this));
    });
  }

  stopMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.removeEventListener(event, this.resetTimeout.bind(this));
    });
    this.clearTimeout();
  }

  resetTimeout(): void {
    this.clearTimeout();
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = window.setTimeout(() => {
        this.ngZone.run(() => this.logoutFromInactivity());
      }, 660000);
    });
  }

  logoutFromInactivity() {
    localStorage.setItem('logoutReason', 'Logged out due to inactivity');
    this.logout();
  }

  clearTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  logout(): void {
    this.stopMonitoring();
    this.terminateSession();
    sessionStorage.clear();
    this.app.cookieService.delete('loggedIn');
    window.location.href = '/signin';
  }

  validateSession(): void {    
    let formData = this.app.buildForm(['sessionId'], [localStorage.getItem('sessionId')])
    this.licensingService.validateSession(formData).subscribe({
      next: (data: { result: boolean, message: string }) => {
        if (!data.result) {
          this.sessionValidationSubject.unsubscribe();
          localStorage.setItem('sessionResult', `Result: ${data.result}. Message: ${data.message}`);
          localStorage.setItem('logoutReason', 'Logged out due to invalid session');
          this.logout();
        }
      }, error: (errorLog) => {
        console.log(errorLog);
      }
    })
  }

  terminateSession(): void {
    let sessionId = Number(localStorage.getItem('sessionId'));    
    this.sessionValidationSubject.unsubscribe();
    if (sessionId > 0) {
      localStorage.removeItem('sessionId');
      let formData = this.app.buildForm(['sessionId'], [sessionId.toString()]);
      this.licensingService.terminateSession(formData).subscribe({
        error: (errorLog) => {
          console.log(errorLog);
        }
      })
    }
  }
}
