import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ISetting } from '../_models/ISetting';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { IconDefinition, faGear, faXmark, faSliders, faBorderAll, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'settings-component',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  @Input() darkMode: boolean;
  @Input() mobileView: boolean = false;
  selectedItem: number = 1;
  updated: boolean = false;
  settingsLoaded: boolean = false;
  followSystemDarkMode: boolean = false;
  darkModeSetting: ISetting = { value: false };
  menuSetting: ISetting = { value: true };
  textSizeSetting: ISetting = { value: 15 };
  compactGridSetting: ISetting = { value: false };
  filterSetting: ISetting = { value: false };
  detailedErrorSetting: ISetting = { value: false };
  stickyHeaderSetting: ISetting = { value: true };
  
  //#region Icons
  faGear: IconDefinition = faGear;
  faXmark: IconDefinition = faXmark;
  faSliders: IconDefinition = faSliders;
  faBorderAll: IconDefinition = faBorderAll;
  faUser: IconDefinition = faUser;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService
  ) { }

  ngOnInit() {
    if (this.app.cookieService.check('matchSystemDarkMode')) {
      this.followSystemDarkMode = true;
      this.detectDarkMode(true);
    } else if (this.app.cookieService.check('darkMode')) {
      this.darkModeSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('darkMode'));
    }

    if (this.app.cookieService.check('MenuToggle')) {
      this.menuSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('MenuToggle'));
    }

    if (this.app.cookieService.check('fontSize')) {
      this.textSizeSetting.value = this.app.cookieService.get('fontSize');
    }

    if (this.app.cookieService.check('CompactGrid')) {
      this.compactGridSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('CompactGrid'));
    }

    if (this.app.cookieService.check('RowFilters')) {
      this.filterSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('RowFilters'));
      this.settingsLoaded = true;
    } else {
      this.settingsLoaded = true;
    }

    if (this.app.cookieService.check('detailedErrors')) {
      this.detailedErrorSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('detailedErrors'));
    }

    if (this.app.cookieService.check('stickyHeaders')) {
      this.stickyHeaderSetting.value = this.app.tryParseBoolean(this.app.cookieService.get('stickyHeaders'));
    }
  }

  followSystem(event: any): void {
    if (event.checked) {
      this.detectDarkMode();
      return;
    }

    this.app.cookieService.delete('matchSystemDarkMode');
  }

  detectDarkMode(initialLoad?: boolean): void {
    if (!initialLoad) this.updated = true;
    let darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = darkModeMediaQuery.matches;
    this.darkModeSetting.value = darkModeMediaQuery.matches;
    this.app.cookieService.set('darkMode', this.darkMode.toString(), 365, '/', '', false);
    this.app.cookieService.set('matchSystemDarkMode', true.toString(), 365, '/', '', false);
  }

  updateDarkModeSetting(value: any): void {
    this.updated = true;
    this.darkModeSetting.value = value;
    this.app.cookieService.delete('matchSystemDarkMode');
    this.followSystemDarkMode = false;
    this.app.cookieService.set('darkMode', value, 365, '/', '', false);
  }

  updateMenuSetting(value: any): void {
    this.updated = true;
    this.menuSetting.value = value;
    this.app.cookieService.set('MenuToggle', value, 365, '/', '', false);
  }

  updateTextSizeSetting(value: any): void {
    this.updated = true;
    this.textSizeSetting.value = value;
    this.app.cookieService.set('fontSize', value, 365, '/', '', false);
    this.app.cookieService.set('padding', value, 365, '/', '', false);
  }

  updateCompactGridSetting(value: any): void {
    this.updated = true;
    this.compactGridSetting.value = value;
    this.app.cookieService.set('CompactGrid', value, 365, '/', '', false);
  }

  updateFilterSetting(value: any): void {
    this.updated = true;
    this.filterSetting.value = value;
    this.app.cookieService.set('RowFilters', value, 365, '/', '', false);
  }

  updateDetailedErrorSetting(value: any): void {
    this.updated = true;
    this.detailedErrorSetting.value = value;
    this.app.cookieService.set('detailedErrors', value, 365, '/', '', false);
  }

  updateStickyHeaderSetting(value: any): void {
    this.updated = true;
    this.stickyHeaderSetting.value = value;
    this.app.cookieService.set('stickyHeaders', value, 365, '/', '', false);
  }

  changePassword(): void {
    sessionStorage.setItem('resetPassword', 'true');
    this.app.router.navigate(['signin']);
  }

  close(): void {
    this.communicationService.closeDialog();
    if (this.updated) window.location.href = '/base';
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }

  selectItem(item: number): void { this.selectedItem = item; }
}
