<div class="row main transition5">
  <div class="menu-position full-vh-height" [ngClass]="{'collapsed-menu': !isMenuExpanded, 'expanded-menu': isMenuExpanded, 
    'unset-p': !isMenuExpanded, 'bg-dark': darkMode, 'bg-white': !darkMode}">
    <div class="flex animated fadeIn cursorPointer align-centre menu-button" (click)="isMenuExpanded = !isMenuExpanded"
      [ngClass]="{'justify-centre': !isMenuExpanded}">
      <div id="nav-icon1" class="textWhite font20" [ngClass]="{open: isMenuExpanded}">
        <span class="bg-green"></span>
        <span class="bg-blue"></span>
        <span class="bg-orange"></span>
      </div>
      <ng-template [ngIf]="app.siteName && isMenuExpanded">
        <span class="font14 animated fadeIn absolute site-name" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
          style="left: 37px">
          {{app.siteName}}
        </span>
      </ng-template>
    </div>
    <div class="animated fadeIn sticky full-width menu-container" [ngStyle]="{'z-index': isMenuExpanded ? '1' : '0'}"
      [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <app-menu [item]="undefined" [isMenuExpanded]="isMenuExpanded" [userId]="userId" [globalFontSize]="globalFontSize"
        [menuItemPadding]="menuItemPadding" [darkMode]="darkMode"></app-menu>
    </div>
  </div>
  <div class="animated fadeIn view-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="flex align-centre sticky toolbar" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="col-10 unset-p">
        <div class="flex">
          <app-rad class="rad-comp" [darkMode]="darkMode"></app-rad>
        </div>
      </div>
      <div class="col-2 flex justify-right">
        <div class="relative notification-base" style="padding: 7px 12px;">
          <button class="font16 unset-b b-radius"
            [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlue bg-light-blue'"
            (click)="openNotifications()">
            <fa-icon [icon]="faBell"></fa-icon>           
          </button>
          <ng-template [ngIf]="hasUnreadNotifications">
            <fa-icon [icon]="faCircle" class="absolute textRed" style="right: 7px;"></fa-icon>            
          </ng-template>
        </div>
        <span class="pull-left font18 bg-blue textWhite cursorPointer" style="padding: 6px 15px; border-radius: 50%;"
          (click)="openUserMenu()">
          {{username.charAt(0)}}
        </span>
        <ng-template [ngIf]="translationsLoaded">
          <div class="absolute animated box-shadow b-radius user-menu-container " [ngClass]="{'slideInRight': !isUserMenuCollapsed, 'slideOutRight': isUserMenuCollapsed, 
            'bg-white': !darkMode, 'bg-gradient-dark': darkMode}">
            <user-menu-component [darkMode]="darkMode" [username]="username" [userRole]="userRole" [greeting]="greeting"
              [mobileView]="mobileView"></user-menu-component>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="relative full-width transition5">
      <ng-template [ngIf]="!gridView && !ganttView && !mapView">
        <div class="animated padding5 fadeIn full-vh-height" [ngClass]="darkMode ? 'bg-gradient-dark' : 'bg-gradient'">
          <img class="absolute padding22 b-radius no-view" src="../assets/img/logo.png" />
        </div>
      </ng-template>
      <div class="animated fadeIn full-vh-height">
        <ng-template [ngIf]="gridView">
          <app-grid [darkMode]="darkMode" [globalFontSize]="globalFontSize" [tablePadding]="tablePadding"
            [userId]="userId" [username]="username" [mobileView]="mobileView" [detailedErrorsEnabled]="detailedErrorsEnabled"></app-grid>
        </ng-template>
        <ng-template [ngIf]="ganttView">
          <app-gantt [darkMode]="darkMode" [globalFontSize]="globalFontSize" [tablePadding]="tablePadding"
            [userId]="userId" [username]="username" [detailedErrorsEnabled]="detailedErrorsEnabled"></app-gantt>
        </ng-template>
        <ng-template [ngIf]="mapView">                    
          <app-map [darkMode]="darkMode" [globalFontSize]="globalFontSize" [tablePadding]="tablePadding"
            [userId]="userId" [username]="username" [detailedErrorsEnabled]="detailedErrorsEnabled"></app-map>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template [ngIf]="!cookieConsent">
  <div
    class="row animated slideInUp flex box-shadow sticky full-width justify-centre align-centre blur cookie-container"
    [ngClass]="{'slideInUp': !cookieConsent, 'slideOutDown': cookieConsent}">
    <div class="col-6 flex align-centre justify-centre">
      <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlue'">
        <ng-template [ngIf]="app.translations != undefined">
          {{app.translations.COOKIEBANNER_Desc}} - <span (click)="openCookiePolicy()"
            class="textGreen cursorPointer">{{app.translations.COOKIEBANNER_Lnk}}</span>
        </ng-template>
      </span>
    </div>
    <div class="col-1">
      <button class="btn float-right" (click)="consentToCookies()">
        <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlue'">
          <fa-icon [icon]="faXmark"></fa-icon>         
        </span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="action && action.type == 'MultiParamSqlExecuteAction'">
  <form-action-component [headers]="headers" [compiledItems]="compiledItems" [action]="action" [nodeId]="nodeId"
    [userId]="userId" [username]="username" [darkMode]="darkMode" [mobileView]="mobileView"></form-action-component>
</ng-template>

<ng-template [ngIf]="action && action.type == 'MultiParamGridAction'">
  <grid-action-component [headers]="headers" [compiledLines]="compiledItems" [action]="action" [nodeId]="nodeId"
    [userId]="userId" [username]="username" [darkMode]="darkMode"></grid-action-component>
</ng-template>

<ng-template [ngIf]="action && action.type == 'SqlExecuteAction'">
  <sql-action-component [compiledItems]="compiledItems" [action]="action" [nodeId]="nodeId" [userId]="userId"
    [darkMode]="darkMode"></sql-action-component>
</ng-template>

<ng-template [ngIf]="feedbackMessage">
  <feedback-component [feedbackMessage]="feedbackMessage" [darkMode]="darkMode"></feedback-component>
</ng-template>

<ng-template [ngIf]="headersToEdit">
  <column-component [headers]="headersToEdit" [nodeId]="nodeId" [userId]="userId" [username]="username"
    [darkMode]="darkMode" [viewName]="viewName"></column-component>
</ng-template>

<ng-template [ngIf]="imageToExpand">
  <image-viewer-component [image]="imageToExpand" [darkMode]="darkMode"></image-viewer-component>
</ng-template>

<ng-template [ngIf]="showSettings">
  <settings-component [darkMode]="darkMode" [mobileView]="mobileView"></settings-component>
</ng-template>

<ng-template [ngIf]="showNotifications">
  <app-notification-component [nodeId]="nodeId" [userId]="userId" [username]="username"
    [darkMode]="darkMode"></app-notification-component>
</ng-template>

<ng-template [ngIf]="showImportFileDialog">
  <import-component [darkMode]="darkMode"></import-component>
</ng-template>